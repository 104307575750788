<template>
  <div class="content">
    <div class="row">
      <div class="col-md-12"></div>
      <div class="col-md-12">
        <vuestic-widget :headerText="header">
          <div class="row">
            <div class="col-md-6">
              <div class="card">
                <img
                  class="card-img-top"
                  style="max-height: 120px"
                  src="@/assets/images/Sales.jpg"
                  alt="Card image cap"
                />
                <div class="card-img-overlay" style="color: white">
                  <h3>
                    <i class="fa fa-dollar"></i>
                  </h3>
                  <h2 style="font-weight: 600; font-size: 25px">
                    Recargas - Devoluciones = Total
                  </h2>
                </div>
                <div class="row">
                  <ul
                    class="list-group col-md-4 no-gutter"
                    style="font-weight: 600; font-size: 14px"
                  >
                    <li class="list-group-item">
                      <h4 style="font-weight: 600">
                        {{ analytic_station.total_topup_ok }} USD
                      </h4>
                    </li>

                    <li class="list-group-item">
                      Total Efectivo:
                      {{ analytic_station.total_topup_ok_cash }} USD
                    </li>
                    <li class="list-group-item">
                      Total Tarjeta:
                      {{ analytic_station.total_topup_ok_card }} USD
                    </li>
                    <li class="list-group-item">
                      Concretadas: {{ analytic_station.count_total_topup_ok }}
                    </li>
                    <li class="list-group-item">
                      Devueltas: {{ analytic_station.count_total_topup_fail }}
                    </li>
                  </ul>
                  <ul
                    class="list-group col-md-4 no-gutter"
                    style="font-weight: 600; font-size: 14px"
                  >
                    <li class="list-group-item">
                      <h4 style="font-weight: 600">
                        - {{ analytic_station.total_refund_ok }} USD
                      </h4>
                    </li>

                    <li class="list-group-item">
                      Total Efectivo: -
                      {{ analytic_station.total_refund_ok_cash }} USD
                    </li>
                    <li class="list-group-item">
                      Total Tarjeta: -
                      {{ analytic_station.total_refund_ok_card }} USD
                    </li>
                    <li class="list-group-item">
                      Concretadas: {{ analytic_station.count_total_refund_ok }}
                    </li>
                    <li class="list-group-item">
                      Devueltas: {{ analytic_station.count_total_refund_fail }}
                    </li>
                  </ul>
                  <ul
                    class="list-group col-md-4 no-gutter"
                    style="font-weight: 600; font-size: 14px"
                  >
                    <li class="list-group-item">
                      <h4 style="font-weight: 600">
                        =
                        {{
                          analytic_station.total_topup_ok -
                          analytic_station.total_refund_ok
                        }}
                        USD
                      </h4>
                    </li>

                    <li class="list-group-item">
                      Total Efectivo: ={{
                        analytic_station.total_topup_ok_cash -
                        analytic_station.total_refund_ok_cash
                      }}
                      USD
                    </li>
                    <li class="list-group-item">
                      Total Tarjeta: =
                      {{
                        analytic_station.total_topup_ok_card -
                        analytic_station.total_refund_ok_card
                      }}
                      USD
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div class="col-md-3">
              <div class="card">
                <img
                  class="card-img-top img-fluid"
                  src="@/assets/images/Devices.jpg"
                  alt="Card image cap"
                />
                <div class="card-img-overlay" style="color: white">
                  <h3>
                    <i class="fa fa-tablet"></i>
                  </h3>
                  <h2 style="font-weight: 600; font-size: 25px">
                    Dispositivos
                  </h2>
                </div>

                <ul class="list-group" style="font-weight: 600">
                  <li class="list-group-item">
                    <h4 style="font-weight: 600">
                      {{ analytic_station.cant_devices }}
                    </h4>
                  </li>

                  <li class="list-group-item">
                    Por Operador: {{ analytic_station.perOperator }}
                  </li>
                  <li class="list-group-item">
                    Avg Sales: {{ analytic_station.avg_topup_device }} USD
                  </li>
                </ul>
              </div>
            </div>

            <div class="col-md-3">
              <div class="card">
                <img
                  class="card-img-top img-fluid"
                  src="@/assets/images/Operator.jpg"
                  alt="Card image cap"
                />
                <div class="card-img-overlay" style="color: white">
                  <h3>
                    <i class="fa fa-id-card"></i>
                  </h3>
                  <h2 style="font-weight: 600; font-size: 25px">Operadores</h2>
                </div>

                <ul class="list-group" style="font-weight: 600">
                  <li class="list-group-item">
                    <h4 style="font-weight: 600">
                      {{ analytic_station.cant_staff }}
                    </h4>
                  </li>

                  <li class="list-group-item">
                    Avg Tips: {{ analytic_station.avg_tips }}
                  </li>
                  <li class="list-group-item">
                    Avg Sales: {{ analytic_station.avg_topup_operator }}
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </vuestic-widget>
        <vuestic-widget>
          <button class="btn-button btn-primary btn-micro" @click="showDuplicateDetail">
            Chequear transacciones
          </button>

          <vuestic-modal
            :show="showModalDuplicate"
            ref="duplicateDetailModal"
            :no-buttons="true"
          >
            <div slot="title">Transacciones que requieren revisión: {{ duplicateTransaction.length }}</div>
            <div>
              <table class="table table-bordered table-sm">
                <thead>
                  <tr>
                    <th scope="col">Monto</th>
                    <th scope="col">Dispositivo</th>
                    <th scope="col">Timestamp</th>
                    <th scope="col">Staff</th>
                    <th scope="col">Incidencias</th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="detailt in duplicateTransaction"
                    :key="detailt.id"
                    style="text-align: center"
                  >
                    <td>{{ detailt.amount }}</td>
                    <td>{{ detailt.device.name }}</td>
                    <td>{{ detailt.timestamp }}</td>
                    <td>{{ detailt.staff.name+' - '+detailt.staff.code }}</td>
                    
                    <td>{{ detailt.count }}</td>
                  </tr>
                  <tr
                    style="
                      background-color: #e3e3e3;
                      text-align: center;
                      font-size: 22px;
                    "
                  >
                    
                    
                  </tr>
                </tbody>
              </table>
            </div>
          </vuestic-modal>
          <div class="col-md-12">
            <vuestic-tabs
              class="tabs"
              :names="[
                'Dispositivos',
                'Recarga en efectivo',
                'Recarga con Débito/Crédito',
                'Devoluciones',
                'Promedio Recargas',
                'Staff',
              ]"
            >
              <div slot="Dispositivos">
                <TableDeviceByStation></TableDeviceByStation>
              </div>
              <div slot="Recarga en efectivo">
                <TableTopupDetail :typePay="1"></TableTopupDetail>
              </div>
              <div slot="Recarga con Débito/Crédito">
                <TableTopupDetail :typePay="2"></TableTopupDetail>
              </div>
              <div slot="Devoluciones">
                <TableRefundDetail></TableRefundDetail>
              </div>
              <div slot="Promedio Recargas">
                <AnalyticTopupByStation></AnalyticTopupByStation>
              </div>
              <div slot="Staff">
                <TableStaffByStation></TableStaffByStation>
              </div>
            </vuestic-tabs>
          </div>
        </vuestic-widget>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from "vue";
import TableStation from "../tables/TableStation/TableStation.vue";
import TableTopupDetail from "../tables/TableStation/TableTopupDetail.vue";
import TableRefundDetail from "../tables/TableStation/TableRefundDetail.vue"




import TableDeviceByStation from "../tables/TableDeviceByStation/TableDeviceByStation.vue";
import TableStaffByStation from "../tables/TableStaffByStation/TableStaffByStation.vue";
import AnalyticTopupByStation from "./AnalyticTopupByStation.vue";

import Multiselect from "vue-multiselect";
import { SpringSpinner } from "epic-spinners";
import { mapState, mapActions } from "vuex";

// example of data
let chartData = {
  labels: ["January", "February"],
  datasets: [
    {
      label: "GitHub Commits",
      backgroundColor: "#f87979",
      data: [40, 20],
    },
  ],
};

export default {
  name: "AnalyticByStation",
  components: {
    SpringSpinner,
    TableStation,
    TableTopupDetail,
    TableDeviceByStation,
    TableStaffByStation,
    AnalyticTopupByStation,
    TableRefundDetail,
    Multiselect,
  },
  mounted() {
    // checkbox and radios

    this.loadTypestationsActive()
      .then((data) => {
        // this.typestationlist = data;
      })
      .catch((data) => {
        this.addToastMessage({
          text: "Error consultando usuarios",
          type: "warning",
        });
      });
    var idstation = this.$route.params.idstation;
    this.loadAnalyticStationTopup(idstation)
      .then((data) => {
        this.analytic_station = data;
      })
      .catch((data) => {
        this.addToastMessage({
          text: "Error consultando usuarios",
          type: "warning",
        });
      });
  },
  computed: {
    ...mapState({
      //  typestationlist: state => state.typestation.typestations,
      //analytic_station: state => state.analytic.station,
      idevent: (state) => state.event.event.id,
    }),
  },
  data() {
    return {
      header: "Analíticas de Estación",
      headerChart1: "Sales per Hour (En desarrollo)",
      headerChart2: "Top Products (En Desarrollo)",
      showModalDuplicate:false,
      duplicateTransaction:[],
      // typestation: [],
      //  categorystation: [],
      // typestationlist: [],
      name: "",
      number: "",
      analytic_station: {
        total_topup_ok:0
      },
      location: "",
      active: 1,
      eventoptions: null,
      //CHART
      chartData: {
        labels: ["Credits", "Virtual"],
        datasets: [
          {
            label: "GitHub Commits",
            backgroundColor: "#f87979",
            data: [0, 24],
          },
        ],
      },
      chartOptions: {
        responsive: true,
        maintainAspectRatio: false,
      },
    };
  },
  methods: {
    ...mapActions([
      "loadTypestationsActive",
      "loadAnalyticStationTopup",
      "loadCategorystations",
      "storeStation",
      "addToastMessage",
      "loadTopupTransactionsDuplicated"
    ]),
    getDuplicates() {
      this.loadTopupTransactionsDuplicated({ id_station: this.$route.params.idstation })
        .then((data) => {
          this.duplicateTransaction = data;
        })
        .catch((data) => {
          this.addToastMessage({
            text: "Error consultando",
            type: "warning",
          });
        });
    },
    showDuplicateDetail() {
      this.getDuplicates();
      this.$refs.duplicateDetailModal.open();
      this.showModalDuplicate = true;
    },
    backPage: function () {
      this.$router.go(-1);
    },
    saveStaff() {
      this.$validator.validate().then((result) => {
        if (result) {
          this.storeStation({
            name: this.name,
            number: this.number,
            location: this.location,
            id_event: this.idevent,
            active: this.active,
            id_type_station: this.typestation.id,
          })
            .then((data) => {
              this.addToastMessage({
                text: data.message,
                type: "success",
              });
              setTimeout(() => this.$router.replace("/station"), 2000);
            })
            .catch((data) => {
              this.error = data.message;
              this.addToastMessage({
                text: data.message,
                type: "warning",
              });
            });
        }
      });
    },
  },
};
</script>

<style lang="scss">
.color-icon-label-table {
  td:first-child {
    width: 1rem;
  }
}
.no-gutter {
  padding-right: 0;
  padding-left: 0;
}
.ul3 {
  -webkit-column-count: 3;
  -moz-column-count: 3;
  column-count: 3;
}
</style>
